<template>
  <BaseList
    :list-query="runeTypes"
    route="runeType"
    locale-section="pages.materialSubTypes"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "RuneTypesList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      runeTypes: gql`
        query runeTypes {
          list: runeTypes {
            id
            name: runeType
            runeType
            category
          }
        }
      `
    };
  }
};
</script>
